












import Vue from 'vue'
import { Location } from 'vue-router'

export default Vue.extend({
    name: 'BaseCard',

    props: {
        href: String,
        to: Object as () => Location,
    },

    computed: {
        tag(): string {
            switch (true) {
                case !!this.href:
                    return 'a'
                case !!this.to:
                    return 'RouterLink'
                default:
                    return 'div'
            }
        },

        isLink(): boolean {
            return !!this.href || !!this.to || !!this.$listeners.click
        },
    },
})
