































import Vue from 'vue'
import { IStateDashboardReferredPeople, IUserReferredPerson, IStateAuth } from '@/types'
import { createNamespacedHelpers } from 'vuex'
import { ACTION_DASHBOARD_REFERRED_PEOPLE, STORE_MODULE } from '@/enums'
import { BaseCard, BaseCardBody, BaseCardTitle } from '@/components/BaseCard'
import { getFormattedDateTime, getFormattedMoneyValue } from '@/utils'

const { mapState: mapStateAuth } = createNamespacedHelpers(STORE_MODULE.AUTH)
const { mapState, mapActions } = createNamespacedHelpers(
    STORE_MODULE.DASHBOARD_USER_REFERRED_PEOPLE
)

export default Vue.extend({
    name: 'ReferredPeople',

    components: { BaseCard, BaseCardBody, BaseCardTitle },

    data: () => ({
        fields: [
            { key: 'name', label: 'Name' },
            { key: 'email', label: 'E-mail' },
            { key: 'jobTitle', label: 'Job title' },
            { key: 'date', label: 'Date', formatter: 'formatDate' },
            { key: 'status', label: 'Status' },
            { key: 'potencialReferralReward', label: 'Potencial Reward' },
        ],
    }),

    computed: {
        ...(mapStateAuth(['userAccount']) as MapStateToComputed<IStateAuth>),

        ...(mapState(['referredPeople']) as MapStateToComputed<IStateDashboardReferredPeople>),

        referralRewardTotalFormatted(): string {
            const total =
                this.referredPeople?.reduce((result, item) => {
                    return (result += item.potencialReferralReward)
                }, 0) ?? 0

            return `${getFormattedMoneyValue(total)} PLN`
        },
    },

    created() {
        this.fetchAndSetReferredPeople(this.userAccount?.id)
    },

    methods: {
        ...mapActions({
            fetchAndSetReferredPeople:
                ACTION_DASHBOARD_REFERRED_PEOPLE.FETCH_AND_SET_REFERRED_PEOPLE,
        }),

        getFormattedDateTime,
    },
})
