























import Vue from 'vue'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

export default Vue.extend({
    name: 'BaseCardTitle',

    props: {
        icon: Object as () => IconDefinition,
    },
})
